<template>
    <div>
        <landing-page-header></landing-page-header>
        <main>
            <div>
                <img src="../../assets/home/place_order.jpg" alt="" style="width:100%; object-fit:cover;">
            </div>
            <div class="container-fluid bg-white pt-5 pb-5">
                <div class="row">
                    <div class="col-12">
                        <div class="">
                            <no-order-weekend-notification></no-order-weekend-notification>
                                <validation-errors :errors="validationErrors"></validation-errors>
                                <div v-if="customerSubscription != null && customerSubscription != ''">
                                    <div v-if="customerSubscription.exceeded == false">
                                        <p>You still have an active subscription, do you want to pay using your subscription?</p>
                                        
                                        <div class="n-chk">
                                            <label class="new-control new-radio radio-warning">
                                                <input type="checkbox" name="pay-with-sub" class="new-control-input" v-model="payWithSub">
                                                <span class="new-control-indicator"></span> Yes, I want to pay using my subscription
                                            </label>
                                        </div>
                                        <div class="alert alert-info" v-if="payWithSub == true">You will have {{ customerSubscription.order_limit - (customerSubscription.order_count + deliveries.length) }} orders left</div>
                                    </div>
                                </div>
                                <div id="toggleAccordion">
                                    <div class="card">
                                        <div class="card-header bg-secondary-light">
                                            <section class="mb-0 mt-0 text-white">
                                                <div role="menu" class="collapsed" data-toggle="collapse" data-target="#pickupAccordion" aria-expanded="true" aria-controls="defaultAccordionOne">
                                                    <i class="fa fa-cart-plus"></i> Add pickup details  
                                                </div>
                                            </section>
                                        </div>

                                        <div id="pickupAccordion" class="collapse show" aria-labelledby="..." >
                                            <div class="card-body">
                                                <div class="" v-if="payWithSub == false">
                                                    <!-- <div class="form-group">
                                                        <label for="Pickup Address">Address *</label>
                                                        <input type="text" class="form-control" name="pickupAddress" @keyup="findAddress($event.target, 'pickupLocation')" v-model="pickupLocation.full_address" placeholder="Pickup Address">
                                                        <div class="help-text text-danger font-weight-bold" v-if="pickupLocation.address_not_found === true">
                                                            Your pickup location could not be found. Please try another location.
                                                        </div>
                                                    </div> -->
                                                    <div class="form-group">
                                                        <label for="Pickup Address">Address *</label>
                                                        <input type="text" class="form-control" name="pickupAddress" v-model="pickupLocation.full_address" placeholder="Pickup Address">
                                                        <div class="help-text text-danger font-weight-bold" v-if="pickupLocation.address_not_found === true">
                                                            Your pickup location could not be found. Please try another location.
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-12 col-sm-6">
                                                            <label for="State">State</label>
                                                            <multiselect value="id" v-model="pickupLocation.state" :options="pickupStatesList" track-by="id" label="name" @select="(selected, id) => {pickupLocation.state_id = selected.id; getPickupCities(selected, id)}" select-label="" deselect-label=""></multiselect>
                                                        </div>
                                                        <div class="form-group col-12 col-sm-6">
                                                            <label for="City">City</label>
                                                            <multiselect value="id" v-model="pickupLocation.lga" :options="pickupCitiesList" track-by="id" label="name" @select="(selected, id) => {pickupLocation.lga_id = selected.id}" select-label="" deselect-label=""></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else class="form-group">
                                                    <label for="Picup Address">Choose Address *</label>
                                                    <select name="pickupAddress" id="pickupAddress" class="form-control" @change="onSelectSubscriptionAddress($event)">
                                                        <option value="">Select pickup address</option>
                                                        <option :value="index" v-for="(l, index) in customerSubscription.user_locations" :key="l.id">{{ l.address }}</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label for="Sender Name">Name *</label>
                                                    <input type="text" name="senderName" id="senderName" class="form-control" placeholder="Sender Name" v-model="senderName" required>
                                                </div>
                                                <div class="form-group">
                                                    <label for="Sender Email">Email *</label>
                                                    <input type="email" name="senderEmail" id="senderEmail" class="form-control" placeholder="Sender Email" v-model="senderEmail" required>
                                                </div>
                                                <div class="form-group">
                                                    <label for="Sender Phone number">Phone number *</label>
                                                    <input type="tel" name="senderPhone" id="senderPhone" class="form-control" placeholder="Phone Number" v-model="senderPhone" required>
                                                </div>
                                                <div class="form-group">
                                                    <label for="Item description">Item description *</label>
                                                    <input type="text" name="item_description" id="item_description" class="form-control" placeholder="What's the item you're delivering?" v-model="itemDescription" required>
                                                </div>
                                                <div class="form-group">
                                                    <label for="Extra notes">Extra details about the pickup</label>
                                                    <textarea name="pickupExtra" id="extraNote" class="form-control" placeholder="Is there anything else we should know about the pickup?" v-model="pickupExtraInfo"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card" v-for="(d, index) in deliveries" :key="index">
                                        <div class="card-header">
                                            <section class="mb-0 mt-0">
                                                <div role="menu" class="collapsed disabled" data-toggle="collapse" :data-target="`#deliveryAccordion${index}`" aria-expanded="false" aria-controls="defaultAccordionTwo">
                                                    <i class="fa fa-truck"></i> Add delivery details ({{ index+1 }})
                                                </div>
                                            </section>
                                        </div>
                                        <div :id="`deliveryAccordion${index}`" class="collapse show" aria-labelledby="...">
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label for="Delivery Address">Address *</label>
                                                    <input type="text" class="form-control" name="deliveryAddress" v-model="d.full_address" placeholder="Delivery Address" required>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col-12 col-sm-4 form-group">
                                                        <label for="country">Country</label>
                                                        <multiselect :options="countriesList" v-model="d.country" track-by="id" label="name" @select="(selected, id) => {d.country_id = selected.id; getDeliveryStates(selected, id, index)}" select-label="" deselect-label=""></multiselect>
                                                    </div>
                                                    <div class="col-12 col-sm-4 form-group">
                                                        <label for="State">State</label>
                                                        <multiselect :options="d.deliveryStatesList" v-model="d.state" track-by="id" label="name" @select="(selected, id) => {d.state_id = selected.id; getDeliveryCities(selected, id, index)}" select-label="" deselect-label=""></multiselect>
                                                    </div>
                                                    <div class="col-12 col-sm-4">
                                                        <label for="State">City</label>
                                                        <multiselect :options="d.deliveryCitiesList" v-model="d.lga" track-by="id" label="name" @select="(selected, id) => {d.lga_id = selected.id}" select-label="" deselect-label=""></multiselect>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="Recipient Name">Name *</label>
                                                    <input type="text" name="recipientName" class="form-control" placeholder="Name" v-model="d.recipientName" required>
                                                </div>
                                                <div class="form-group">
                                                    <label for="Recipient Email">Email</label>
                                                    <input type="email" name="recipientEmail" class="form-control" placeholder="Email" v-model="d.recipientEmail">
                                                </div>
                                                <div class="form-group">
                                                    <label for="Recipient Phone number">Phone number *</label>
                                                    <input type="tel" name="recipientPhone" id="recipientPhone" class="form-control" placeholder="Phone Number" v-model="d.recipientPhone" required>
                                                </div>
                                                <div class="form-group">
                                                    <label for="Extra info">Extra details about the deliver</label>
                                                    <textarea name="deliveryExtra" id="deliveryExtra" placeholder="Is there anything else we should know about the delivery?" v-model="d.deliveryExtraInfo" class="form-control"></textarea>
                                                </div>
                                                <div v-if="deliveries.length > 1">
                                                    <button class="btn btn-danger float-right mb-3" @click="removeDeliveryLocation(index)">Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="">
                                            <button class="float-right btn btn-outline-dark mb-3" data-toggle="collapse" data-target="#summaryAccordion" @click="getEstimate">Get estimate</button>
                                        </div>
                                        
                                        <button class="btn btn-secondary" @click="addNewDeliveryLocation">Add new delivery</button>
                                    </div>
                                    <div class="card" v-show="estimateAvailable">
                                        <div class="card-header">
                                            <section class="mb-0 mt-0">
                                                <div role="menu" class="collapsed" data-toggle="collapse" data-target="#summaryAccordion" aria-expanded="false" aria-controls="defaultAccordionThree">
                                                    <i class="fa fa-receipt"></i> Confirm order
                                                </div>
                                            </section>
                                        </div>
                                        <div id="summaryAccordion" class="collapse" aria-labelledby="...">
                                            <div class="card-body">
                                                <div v-if="estimateFound === true">
                                                    Total Price: <span class="h5">₦{{totalCost}}</span>
                                                    <br><br>
                                                    <div class="alert alert-info mb-4" role="alert">
                                                        <i class="fa fa-exclamation-circle"></i> The price displayed is subject to change. Our rider will be on the way to you shortly to pickup your order
                                                    </div>
                                                    <div class="mt-3 mb-3 pb-3">
                                                        <button class="btn btn-warning float-right" @click="placeOrder">Signin to place order</button>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <span class="h5">Price will be calculated after you submit order</span>
                                                    <div class="alert alert-info mb-4" role="alert">
                                                        <i class="fa fa-exclamation-circle"></i>Our rider will be on the way to you shortly to pickup your order. <br> Your order price will be determined after our rider picks up your order.
                                                    </div>
                                                    <div class="mt-3 mb-3 pb-3">
                                                        <button class="btn btn-warning float-right" @click="placeOrder">Signin to place order</button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                            </div>
                        <!-- <div class="statbox widget box box-shadow">
                            <div class="widget-header">
                                <div class="row">
                                    <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                        <h4>Place new order</h4>
                                    </div>
                                </div>
                            </div>
                            
                        </div> -->
                    </div>
                </div>
            </div>
        </main>
        <landing-page-footer></landing-page-footer>
    </div>
</template>
<script>
import header from "./layout/header.vue"
import footer from "./layout/footer.vue"
import instance from '../../presenter/api/network'
import Multiselect from '@suadelabs/vue3-multiselect'
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css'
import { loadScripts } from '../../utils'
import Auth from '../../helpers/auth'
import { useToast } from 'vue-toastification'
import ValidationErrors from "../../components/ValidationErrors"
import NoOrderWeekendNotification from '../../components/NoOrderWeekendNotification.vue'
export default {
    components: {landingPageHeader: header, landingPageFooter: footer, Multiselect, ValidationErrors, NoOrderWeekendNotification},
    mounted(){
        this.getCountries().then(() => this.getPickupStates()).catch((err) => console.log(err));
        loadScripts([
            "assets/vendor/js/components/ui-accordions.js",
            "assets/plugins/autocomplete/jquery.autocomplete.js",
        ])
    },
    data(){
        return {
            pickupLocation: {
                full_address: "",
                country: "",
                state: "",
                lga: "",
                street: "",
                country_id: "",
                state_id: "",
                lga_id: "",
                google_address_selected: false,
                address_not_found: false,
            },
            senderName: "",
            senderEmail: "",
            senderPhone: "",
            pickupExtraInfo: "",
            deliveries: [],
            deliveryTemplate: {
                full_address: "",
                country: "",
                state: "",
                lga: "",
                street: "",
                country_id: "",
                state_id: "",
                lga_id: "",
                google_address_selected: false,
                address_not_found: false,
                recipientName: "",
                recipientEmail: "",
                recipientPhone: "",
                deliveryExtraInfo: "",
                deliveryStatesList: [],
                deliveryCitiesList: []
            },
            itemWeight: "",
            totalCost: 0,
            estimateFound: true,
            itemDescription: "",
            completedOrderId: null,
            orderCompleted: false,
            estimateAvailable: "",
            validationErrors: "",
            customerSubscription: null,
            payWithSub: false,
            selectedSubscriptionLocation: "",
            countriesList: [],
            defaultCountry: null,
            pickupStatesList: [],
            pickupCitiesList: [],
        }
    },
    methods: {
        getCountries(){
            return instance.get("getCountryData").then(res => {
                    this.countriesList = res.data.data;
                    let countryNigeria = this.countriesList.find(item => {
                        return item.name.toLowerCase() == "nigeria";
                    });
                    this.defaultCountry = this.pickupLocation.country = countryNigeria; // = this.deliveryTemplate.country = countryNigeria;
                    this.pickupLocation.country_id = this.deliveryTemplate.country_id = countryNigeria.id;
                    this.addNewDeliveryLocation();
                    
                }).catch(err => {
                    console.log(err);
                })
        },
        getPickupStates(){
            
            instance.get(`getStateData?country_id=${this.defaultCountry.id}&for=pickup`).then(res => {
                this.pickupStatesList = res.data.data;
                // this.deliveryTemplate.deliveryStatesList = res.data.data;
                // this.deliveries.forEach((d, index) => {
                //     this.deliveries[index].deliveryStatesList = res.data.data;
                // })
            })
        },
        getPickupCities(selectedOption, id){
            this.pickupLocation.lga = "";
            instance.get(`getCityData?state_id=${selectedOption.id}&for=pickup`).then(res => {
                this.pickupCitiesList = res.data.data;
            })
        },
        getDeliveryStates(selectedOption, id, index){
            this.deliveries[index].state = "";
            this.deliveries[index].lga = "";
            instance.get(`getStateData?country_id=${selectedOption.id}&for=delivery`).then(res => {
                this.deliveries[index].deliveryStatesList = res.data.data;
            })
        },
        getDeliveryCities(selectedOption, id, index){
            this.deliveries[index].lga = "";
            instance.get(`getCityData?state_id=${selectedOption.id}&for=delivery`).then(res => {
                this.deliveries[index].deliveryCitiesList = res.data.data;
            })
        },
        addNewDeliveryLocation(){
            // if (this.payWithSub == true){
            //     let ordersLeft = this.customerSubscription.order_limit - this.customerSubscription.order_count - this.deliveries.length;
            //     if (ordersLeft < 1){
            //         alert("You have reached maximum number of orders you can use your subscription for.");
            //         return false;
            //     }
            // }
            this.deliveries.push({...this.deliveryTemplate});
        },
        removeDeliveryLocation(index){
            this.deliveries.splice(index, 1);
        },
        getEstimate(){
            this.$root.startRequestLoader();
            let pickup = this.pickupLocation;
            let delivery = [];
            this.deliveries.forEach(d => {
                delivery.push({
                    country_id: d.country_id,
                    state_id: d.state_id,
                    city_id: d.lga_id
                })
            });
            
            let formData = {
                from: {country_id: pickup.country_id, state_id: pickup.state_id, city_id: pickup.lga_id},
                to: delivery
            }
            
            instance.post(`getPriceEstimate`, formData).then(res => {
                
                let response = res.data.data;
                if (res.data.message == "no price"){
                    this.estimateFound = false
                }else{
                    this.estimateFound = true
                    this.totalCost = res.data.data.total;
                }
                this.estimateAvailable = true;
            }).catch(err => {
                if (err.response){
                    useToast().error(err.response?.message ?? "There was an error getting estimates, please try again")
                }else{
                    useToast().error("An unknown error occured");
                }
                
            }).finally(() => {
                this.$root.endRequestLoader();
            })
            
        },
        placeOrder(){
            let orderPrePlacementDetails = {
                pickup: this.pickupLocation,
                delivery: this.deliveries
            }

            localStorage.setItem('orderPrePlacementDetails', JSON.stringify(orderPrePlacementDetails));
            if (Auth.isLoggedIn()){
                this.$router.push("/create-order");
            }else{
                this.$router.push('/register');
            }
        }
    }
}
</script>
<style scoped>
@import url("../../assets/lib/vendor/css/components/tabs-accordian/custom-accordions.css");
@import url("../../assets/lib/plugins/autocomplete/autocomplete.css");
@import url("../../assets/lib/vendor/css/forms/theme-checkbox-radio.css");

</style>